<template>
  <layout-page
    :back="{ name: 'sayl-connect_dashboard-dashboard' }"
    class="module-user link-wallet"
    :title="$t('conn3ct-wallet.link_wallet_title')"
  >
    <div class="link-wallet__body">
      <ui-panel>
        <div class="body">
          <p v-html="$t('conn3ct-wallet.link_wallet_description')"></p>
        </div>
      </ui-panel>

      <ui-panel>
        <template v-slot:header>
          <h4 class="title">{{ $t('conn3ct-wallet.link_wallet_process_title') }}</h4>
        </template>

        <div class="body">
          <forms-select
            v-model="chainId"
            :options="chainOptions"
            :placeholder="$t('conn3ct-wallet.chain_select_placeholder')"
            required
          ></forms-select>

          <div
            v-if="!$basil.isNil(chainId)"
            class="link-wallet__connectors"
          >
            <actions-button
              v-show="isEther"
              @click="connectWithWalletConnect"
              class="link-wallet__option"
            >
              <img :src="$root.getSrc('statics/images/wallet-connect.svg')" />
              {{ $t('conn3ct-wallet.connect_with_wallet_connect') }}
            </actions-button>

            <actions-button
              v-show="hasEthereum && isEther"
              @click="connectionWithMetamask"
              class="link-wallet__option"
            >
              <img :src="$root.getSrc('statics/images/metamask.svg')" />
              {{ $t('conn3ct-wallet.connect_with_metamask') }}
            </actions-button>

            <actions-button
              v-show="isHedera"
              @click="connectWithHashconnect"
              class="link-wallet__option"
            >
              <img :src="$root.getSrc('statics/images/hash-pack.svg')" />
              {{ $t('conn3ct-wallet.connect_with_hashconnect') }}
            </actions-button>
          </div>

          <!-- <actions-button
            @click="linkWallet"
            :appearance="$pepper.Appearance.PRIMARY"
            class="link-wallet__submit"
            :disabled="!isValid"
          >{{ $t('conn3ct-wallet.connect_wallet') }}</actions-button> -->

          <span
            v-if="isWalletLinked"
            class="link-wallet__error"
          >{{ $t('conn3ct-wallet.wallet-already-linked') }}</span>

          <span
            v-if="!$basil.isNil(errorMessage) && !isWalletLinked"
            class="link-wallet__error"
          >{{ errorMessage }}</span>
        </div>
      </ui-panel>

      <popins-modal
        @close="onCloseModal"
        :closable="false"
        position="middle-center"
        :size="$pepper.Size.S"
        :visible="showModal"
        :token="token"
        :wallet="wallet"
      >
        <template v-slot:header>
          <h3>{{ $t('conn3ct-wallet.link_wallet_modal_title') }}</h3>
        </template>

        <div
          v-if="wallet"
          class="modal-link-wallet__body"
        >
          <div class="modal-link-wallet__entry">
            <span>{{ $t('conn3ct-wallet.address_label') }}</span>
            <span>{{ shortAddress }}</span>
          </div>

          <forms-input
            v-model="name"
            class="modal-link-wallet__name"
            :placeholder="wallet.name"
          >{{ $t('conn3ct-wallet.link_wallet_name_lbl') }}</forms-input>
        </div>

        <template v-slot:footer>
          <div class="modal-link-wallet__actions">
          <actions-button
            @click="onSubmit"
            :appearance="$pepper.Appearance.PRIMARY"
            class="modal-link-wallet__submit"
          >{{ $t('conn3ct-wallet.link_wallet_submit_lbl') }}</actions-button>
          </div>
        </template>
      </popins-modal>
    </div>
  </layout-page>
</template>

<script>
import WalletConnect from '@walletconnect/client'
import QRCodeModal from "@walletconnect/qrcode-modal"
import { convertUtf8ToHex } from '@walletconnect/utils'
import { ethers } from 'ethers'

import LayoutLanding from '@/layouts/landing.vue'

import { mapState } from 'vuex'
// import { HashConnect } from 'hashconnect'

export default {
  name: 'ExternalWalletLink',

  components: {
    LayoutLanding,
  },

  data() {
    return {
      walletAddress: null,

      walletConnectConnector: null,
      etherProvider: null,
      hashconnectConnector: null,

      challenge: null,
      signature: null,
      hederaPariginString: null,
      hederaState: null,
      hederaSigner: null,

      showModal: false,

      token: null,
      provider: null,
      wallet: null,
      error: null,
      redirectUrl: null,

      chainId: null,
      errorMessage: null,
      name: null,

      chainOptions: [
        // {
        //   label: this.$t('conn3ct-wallet.connect_hedera_wallet'),
        //   value: 290
        // },
        {
          label: this.$t('conn3ct-wallet.connect_eth_wallet'),
          value: 1,
        },
        {
          label: this.$t('conn3ct-wallet.connect_bsc_wallet'),
          value: 56
        },
        {
          label: this.$t('conn3ct-wallet.connect_matic_wallet'),
          value: 137
        },
        {
          label: this.$t('conn3ct-wallet.connect_avax_wallet'),
          value: 43114
        },
        // {
        //   label: this.$t('conn3ct-wallet.connect_cronos_wallet'),
        //   value: 250
        // },
        // {
        //   label: this.$t('conn3ct-wallet.connect_phantom_wallet'),
        //   value: 25
        // }
      ],
    }
  },

  computed: {
    ...mapState({
      bootstrap: state => state.bootstrap,
    }),

    shortAddress() {
      return `${this.walletAddress?.substring(0, 5)}...${this.walletAddress?.substring(this.walletAddress?.length - 5)}`
    },

    hasEthereum() {
      return !this.$basil.isNil(window.ethereum);
    },

    isEther() {
      let ids = [1, 56, 137, 43114]

      return ids.find(id => id === parseInt(this.chainId))
    },

    isHedera() {
      return parseInt(this.chainId) === 290
    },

    isValid() {
      return !this.$basil.isNil(this.chainId) && !this.$basil.isNil(this.walletAddress) && !this.$basil.isNil(this.signature)
    },

    isWalletLinked() {
      if(!this.error || !this.error.get) {
        return false
      }

      return this.error.get('address')
    },

    merchantLogo() {
      return this.bootstrap?.data?.brand?.logo
    },

    logo() {
      if(this.merchantLogo) {
        return this.translate(this.merchantLogo.url)
      }

      return this.$root.getSrc('statics/images/sayl-logo-color.svg')
    }
  },

  methods: {
    async onSubmit() {
      if(this.$basil.isNil(this.name)) {
        return this.onCloseModal()
      }

      await this.$externalWallet.updateNameNoSession({ token: this.token, name: this.name, id: this.wallet.id })
      return this.onCloseModal()
    },

    createWalletConnectConnector() {
      this.walletConnectConnector = new WalletConnect({
        bridge: "https://bridge.walletconnect.org",
        qrcodeModal: QRCodeModal
      });

      this.walletConnectConnector.on('connect', async (err, payload) => {
        if(err) {
          $console.error(err);
          return
        }

        try {
          this.walletAddress = this.$basil.get(payload, 'params[0].accounts[0]')
          this.signature = await this.walletConnectConnector.signPersonalMessage([convertUtf8ToHex(this.challenge), this.walletAddress])
          await this.linkWallet()
        } catch(e) {
          $console.error(e)
        }
      })
    },

    async connectWithHashconnect() {
      // try {
      //   this.hashconnectConnector.foundExtensionEvent.once((walletMetadata) => {
      //     this.hashconnectConnector.connectToLocalWallet(this.hederaPariginString, walletMetadata)
      //   })
      //   this.hashconnectConnector.pairingEvent.once(this.hashconnectWalletPaired)

      //   await this.hashconnectConnector.init({
      //     name: this.$t('conn3ct-wallet.hashconnect_data_name'),
      //     description: this.$t('conn3ct-wallet.hashconnect_data_description'),
      //     icon: 'https://cdn-apps.sayl.cloud/conn3ct-wallet/favicon.ico'
      //   })

      //   this.hederaState = await this.hashconnectConnector.connect()
      //   this.hederaPariginString = this.hashconnectConnector.generatePairingString(this.hederaState, 'mainnet', false)

      //   this.hashconnectConnector.findLocalWallets()
      // } catch(e) {
      //   $console.error(e)
      // }
    },

    async connectWithWalletConnect() {
      try {
        this.createWalletConnectConnector()
        this.provider = 'wallet_connect'
        this.challenge = await this.$externalWallet.getLinkMessage({ token: this.token, chainId: this.chainId, provider: 'wallet_connect' })
        await this.walletConnectConnector.createSession()
      } catch(e) {
        this.errorMessage = this.$t('conn3ct-wallet.signing_message_error')
        $console.error(e)
      }
    },

    async connectionWithMetamask() {
      try {
        this.provider = 'metamask'
        this.challenge = await this.$externalWallet.getLinkMessage({ token: this.token, chainId: this.chainId, provider: 'metamask' })
        await this.etherProvider.send('eth_requestAccounts', [])
        this.walletAddress = await this.etherProvider.getSigner().getAddress()
        this.signature = await this.etherProvider.getSigner().signMessage(this.challenge)
        await this.linkWallet()
      } catch(e) {
        this.errorMessage = this.$t('conn3ct-wallet.signing_message_error')
        $console.error(e)
      }
    },

    async hashconnectWalletPaired(pairingData) {
      try {
        console.log({ pairingData })
        // let provider = hethers.getDefaultProvider()
        // console.log(new VoidSigner())
        // let signer = VoidSigner.connect(provider)
        // signer.connect(provider)
        // console.log({provider, hethers, signer})
        // signer.signMessage('test')
        // let provider = this.hashconnectConnector.getProvider('mainnet', this.$basil.get(this.hederaState, 'topic'), this.$basil.get(pairingData, 'accountIds[0]'))
        // let signer = this.hashconnectConnector.getSigner(provider)
        // console.log(signer)
        this.challenge = await this.$externalWallet.getLinkMessage({ token: this.token, chainId: this.chainId, provider: 'hash_connect' })
        // let res = await this.hashconnectConnector.authenticate(
        //   this.$basil.get(pairingData, 'topic'),
        //   this.$basil.get(pairingData, 'accountIds[0]'),
        //   this.$basil.get(this.challenge, 'serverSigningAccount'),
        //   this.$basil.get(this.challenge, 'signature'),
        //   {}/* payload */
        // )
        console.log(res)
      } catch(e) {
        $console.error(e)
      }
    },

    async linkWallet() {
      try {
        let { response, data } = await this.$externalWallet.signMessage({
          token: this.token,
          chainId: this.chainId,
          signature: this.signature,
          provider: this.provider,
          address: this.walletAddress
        })

        this.wallet = response
        this.redirectUrl = this.$basil.get(data, 'redirectUrl')

        this.$notification({
          title: this.$t('conn3ct-wallet.external_wallet_linked'),
          type: this.$pepper.Intent.SUCCESS
        })

        this.showModal = true
      } catch(e) {
        this.error = e
      }
    },

    onCloseModal() {
      this.showModal = false

      if(!this.$basil.isNil(this.redirectUrl)) {
        this.$router.push(this.redirectUrl)
      }
    }
  },

  mounted() {
    // this.hashconnectConnector = new HashConnect()

    this.token = this.$basil.get(this.$route, 'query.token')

    if(this.hasEthereum) {
      this.etherProvider = new ethers.providers.Web3Provider(window.ethereum);
    }
  },
}
</script>
